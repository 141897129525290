import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './components/Home';
import AccompagnementPorteursProjets from './components/AccompagnementPorteursProjets';
import AccompagnementActeursMarketing from './components/AccompagnementActeursMarketing';
import AccompagnementDecisionnaires from './components/AccompagnementDecisionnaires';
import Engagements from './components/Engagements';
import MentionsLegales from './components/MentionsLegales';
import OffreActeursMarketing from './components/OffreActeursMarketing';
import OffreDecisionnaires from './components/OffreDecisionnaires';
import OffrePorteursProjets from './components/OffrePorteursProjets';

function CanonicalTag() {
  const location = useLocation();
  const canonicalUrl = `https://www.lecatalyst.fr${location.pathname}`;

  return (
    <Helmet>
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
}

function App() {
  return (
    <Router>
      <div id="root">
        <Helmet>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="title" content="Le Catalyst | Partenaire expert en valorisation scientifique" />
          <meta name="description" content="Le Catalyst, expert en communication scientifique et marketing beauté. Stratégies de marque, valorisation, innovation et expertise scientifique." />
          <meta name="keywords" content="Communication scientifique, Agence de communication, Stratégie de marque, Expertise scientifique, Produits innovants, Insights scientifiques, Communication d'entreprise, Urgences internes, Solutions de communication, Valorisation scientifique, beauté, marketing, valorisation, scientifique, lecatalyst, le catalyst, accompagnement, aide, décisionnaires, porteurs de projets, acteurs marketing, offre, science" />
          <meta name="robots" content="index, follow" />
          <meta name="language" content="fr" />
          <meta name="author" content="Corentin Esteve" />
          <link rel="shortcut icon" href="./images/favicon.png" type="image/x-icon" />

          {/* Open Graph Meta Tags */}
          <meta property="og:title" content="Le Catalyst | Partenaire expert en valorisation scientifique" />
          <meta property="og:description" content="Experts en communication scientifique, nous créons des stratégies de marque percutantes et des solutions pour produits innovants." />
          <meta property="og:image" content="https://www.lecatalyst.fr/assets/og-image.jpg" />
          <meta property="og:url" content="https://www.lecatalyst.fr" />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="fr_FR" />
          <meta property="og:site_name" content="Le Catalyst" />

          {/* Twitter Card Meta Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Le Catalyst | Partenaire expert en valorisation scientifique" />
          <meta name="twitter:description" content="Experts en communication scientifique, nous créons des stratégies de marque percutantes et des solutions pour produits innovants." />
          <meta name="twitter:image" content="https://www.lecatalyst.fr/assets/twitter-image.jpg" />
          <meta name="twitter:site" content="@LeCatalyst" />

          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap" rel="stylesheet" />
          <link href="https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Montserrat:ital,wght@0,100;0,200;0,300;1,500&display=swap" rel="stylesheet" />
          <script src="https://kit.fontawesome.com/9dddc8a281.js" crossorigin="anonymous"></script>
        </Helmet>
        <CanonicalTag />
        <Navbar />
        <div className="wrapper">
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/accompagnement_porteur" element={<AccompagnementPorteursProjets />} />
              <Route path="/accompagnement_acteurs_marketing" element={<AccompagnementActeursMarketing />} />
              <Route path="/accompagnement_decisionnaires" element={<AccompagnementDecisionnaires />} />
              <Route path="/engagements" element={<Engagements />} />
              <Route path="/mentions_legales" element={<MentionsLegales />} />
              <Route path="/offre_acteurs_marketing" element={<OffreActeursMarketing />} />
              <Route path="/offre_decisionnaires" element={<OffreDecisionnaires />} />
              <Route path="/offre_porteurs_projets" element={<OffrePorteursProjets />} />
            </Routes>
          </main>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
