import React from 'react';
import { useNavigate } from 'react-router-dom';

const OffreActeursMarketing = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  // Function to navigate and scroll with offer name
  const navigateAndScroll = (offerName) => {
    navigate(`/?offer=${encodeURIComponent(offerName)}#nousContacter`);
  };

  return (
    <div className="body-content offres-acteurs">
      <section className="offre">
        <h3>Nos offres - Acteurs Marketing de la Beauté</h3>
        <h4>Projets impactants</h4>
        <h5>Offres pour les acteurs marketing de la beauté</h5>
        <div className="support">
          <img src="/images_low/products.png" alt="Offre acteurs marketing" />
          <div className="text">
            <h4>Support scientifique stratégique</h4>
            <p>
              De l'élaboration de stratégies réactives face à des tendances au
              développement de concepts scientifiques, nous établissons ensemble vos
              besoins stratégiques lors d'une prise de brief gratuite. Nous vous envoyons
              ensuite un devis qui s'appuie sur un taux journalier en fonction de la
              complexité de la mission.
            </p>
            <div className="price-action">
              <p className="price">TJ 1 000€ à 1100€ HT</p>
              <button
                className="action"
                onClick={() => navigateAndScroll('Support Scientifique Stratégique')}
              >
                Parlons-en<i className="fas fa-arrow-right"></i>
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="offre2">
        <div className="text">
          <h4>Support scientifique opérationnel</h4>
          <p>
            Besoin de supports techniques, d'un porte-parole scientifique ou d'une
            expertise communication scientifique, nous établissons ensemble vos besoins
            opérationnels lors d'une prise de brief gratuite. Nous vous envoyons ensuite
            un devis qui s'appuie sur un taux journalier en fonction de la complexité de
            la mission.
          </p>
          <div className="price-action">
            <p className="price">TJ 800€ à 900€ HT</p>
            <button
              className="action"
              onClick={() => navigateAndScroll('Support Scientifique Opérationnel')}
            >
              Parlons-en<i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <img src="/images_low/offre2.png" alt="Offre 2" />
      </section>

      <section className="offre2 offre-sos">
        <img src="/images_low/product2.png" alt="Offre urgente" />
        <div className="text">
          <h4>
            Offre <strong>SOS</strong> urgence deadline
          </h4>
          <p>
            Un contre temps dans vos projets, dans votre to-do ? Besoin de préparer en
            urgence une réunion ou un concept ? Nous vous proposons ce service premium
            qui vous assure notre entière disponibilité et priorisons votre projet. Nous
            nous efforçons de vous apporter une solution dans les 24h à partir de la
            prise de brief. Ce service est utilisable en une fois et est non cumulable
            dans la semaine.
          </p>
          <div className="price-action">
            <p className="price">5000 € HT</p>
            <button className="action" onClick={() => navigateAndScroll('Offre SOS')}>
              Parlons-en<i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </section>

      <section className="soutien">
        <h4>Soutien continu</h4>
        <p>Un partenaire expert en communication scientifique à portée de main en réunion.</p>
        <table>
          <tbody>
            <tr>
              <td>
                <img src="/images_low/abo1.png" alt="Abonnement 1 mois" />
              </td>
              <td>
                <h5>ABONNEMENT 1 MOIS</h5>
                <p>1h/semaine cumulable dans le mois</p>
              </td>
              <td className="price">500 € / MOIS</td>
            </tr>
            <tr>
              <td>
                <img src="/images_low/abo3.png" alt="Abonnement 3 mois" />
              </td>
              <td>
                <h5>ABONNEMENT 3 MOIS</h5>
                <p>1h/semaine cumulable dans le mois</p>
              </td>
              <td className="price">450 € / MOIS</td>
            </tr>
            <tr>
              <td>
                <img src="/images_low/abo12.png" alt="Abonnement 12 mois" />
              </td>
              <td>
                <h5>ABONNEMENT 12 MOIS</h5>
                <p>1h/semaine cumulable dans le mois</p>
              </td>
              <td className="price">400 € / MOIS</td>
            </tr>
          </tbody>
        </table>
        <button className="action" onClick={() => navigateAndScroll('Soutien continu')}>
          Parlons-en<i className="fas fa-arrow-right"></i>
        </button>
      </section>
    </div>
  );
};

export default OffreActeursMarketing;