import React from 'react';
import { Link } from 'react-router-dom';

const AccompagnementDecisionnaires = () => {
  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  return (
    <div className="body-content decisionnaire">
      <section id="porteur-projet">
        <h3>Décisionnaires Marque Beauté</h3>
        <div className="porteur-projet">
          <div className="text">
            <h4>L’accompagnement des décisionnaires marque beauté</h4>
            <p>Développez votre territoire scientifique et renforcez votre stratégie de marque avec une expertise externe dédiée.</p>
          </div>
          <img src="/images_low/decisionnaire.png" alt="Porteur de projet" />
        </div>
      </section>

      <section id="nosSolutions">
        <h3>Nos Solutions</h3>
        <h4>Nos solutions pour valoriser votre marque par une approche scientifique innovante</h4>
        <p>
          Vous êtes à la tête de la direction générale, communication scientifique, marketing ou communication, et vous avez besoin d'une équipe de communication scientifique externalisée ? Nous vous accompagnons sur des projets globaux, de l'établissement du territoire scientifique jusqu'à la gestion d'événements scientifiques.
        </p>
        <div className="nosSolutions-cards">
          <div className="nosSolutions-card">
            <img src="/images_low/solution1.png" alt="stratégie de projet" />
            <p>Nous vous aidons à construire un territoire scientifique pour renforcer votre expertise et guider vos décisions marketing</p>
          </div>
          <div className="nosSolutions-card">
            <img src="/images_low/solution2.png" alt="stratégie de projet" />
            <p>Nous travaillons des concepts scientifiques pour enrichir votre expertise et valoriser votre marque</p>
          </div>
          <div className="nosSolutions-card">
            <img src="/images_low/solution3.png" alt="stratégie de projet" />
            <p>Nous créons vos books scientifiques, véritable référence pour toutes les informations techniques et scientifiques de vos produits</p>
          </div>
          <div className="nosSolutions-card">
            <img src="/images_low/solution4.png" alt="stratégie de projet" />
            <p>Nous élaborons une stratégie de communication scientifique pour valoriser votre expertise</p>
          </div>
          <div className="nosSolutions-card">
            <img src="/images_low/ticket.png" alt="stratégie de projet" />
            <p>Nous vous accompagnons dans la création et la gestion d'évènements scientifiques</p>
          </div>
        </div>
        <Link to="/offre_decisionnaires"
          className="action"
          onClick={scrollToTop}
        >
          Voir nos offres<i className="fas fa-arrow-right"></i>
        </Link>
      </section>

      <section id="nosPartenaires" className="partenaire_porteur_projet">
        <h3>Nos Partenaires</h3>
        <h4>Ils vous accompagnent aussi</h4>
        <p className="sous-texte">
          Chez Le Catalyst, nous croyons fermement à la valeur de la collaboration et à la synergie des compétences. C'est pourquoi nous travaillons en étroite collaboration avec un réseau de freelances et entreprises talentueux, chacun apportant une expertise spécifique et complémentaire à notre équipe. Que ce soit un copywriter spécialisé dans la communication scientifique, un expert en sourcing d'actifs, du pilotage d'étude clinique ou un professionnel de la réglementation, nous les avons sélectionnés pour leurs compétences qui complémentent les nôtres et leur capacité à répondre aux besoins variés de nos clients. Tous partagent notre engagement à créer un lien entre la science et le marketing, contribuant ainsi à offrir des solutions adaptées et efficaces pour nos clients.
        </p>
        <div className="nosPartenaires">
          <div>
            <img src="/images/experts/Camille_Andrieu.jpg" alt="Camille ANDRIEU" />
            <p className="name">Camille ANDRIEU</p>
            <p className="description">Experte Rédaction</p>
            <hr className="line" />
            <p>« Science et marketing s’enrichissent mutuellement : par sa vérité, la science donne au marketing son assise, tandis que le marketing sublime la science dans l’application de son utilité. »</p>
            <a href="https://www.camilleandrieu-redaction.com/" target="_blank" rel="noopener noreferrer">www.camilleandrieu-redaction.com<i className="fa-solid fa-arrow-up-right-from-square"></i></a>
          </div>
          <div>
            <img src="/images/experts/Camille_Bassier.jpg" alt="Camille BASSIER" />
            <p className="name">Camille BASSIER</p>
            <p className="description">Experte Règlementation</p>
            <hr className="line" />
            <p>« La science est le pilier de l'efficacité des produits, le marketing les enveloppe d'une histoire captivante pour nos consommateurs, le tout sous l'œil vigilant de la réglementation pour assurer la sécurité. »</p>
            <a href="https://www.insidecosmetics.fr/" target="_blank" rel="noopener noreferrer">www.insidecosmetics.fr<i className="fa-solid fa-arrow-up-right-from-square"></i></a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AccompagnementDecisionnaires;
