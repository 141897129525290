import React from 'react';
import { Link } from 'react-router-dom';

const AccompagnementActeursMarketing = () => {

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };
  
  return (
    <div className="body-content acteurs-marketing">
      <section id="porteur-projet">
        <h3>Acteurs marketing beauté</h3>
        <div className="porteur-projet">
          <div className="text">
            <h4>L’accompagnement des acteurs marketing beauté</h4>
            <p>Optimisez vos développements de produits et campagnes en intégrant des insights scientifiques approfondis et exploitez pleinement le potentiel de vos concepts.</p>
          </div>
          <img src="/images_low/acteur-marketing.png" alt="Acteur marketing" />
        </div>
      </section>

      <section id="nosSolutions">
        <h3>Nos Solutions</h3>
        <h4>Nos solutions pour faire de la science un pilier de votre réussite marketing</h4>
        <p>Vous êtes chef de projet ou manager d’une équipe marketing et on vous demande souvent de traduire des insights scientifiques en concepts marketing sans bagage scientifique suffisant ? Vous devez souvent vous débrouiller seul-e, ce qui peut conduire à des frustrations et à des concepts qui ne sont pas exploités à 100 % ? Nous sommes là pour combler ce maillon manquant entre vous et les métiers scientifiques. Nous vous aidons à transformer des données scientifiques complexes en campagnes marketing impactantes.</p>
        <div className="nosSolutions-cards">
          <div className="nosSolutions-card">
            <img src="/images_low/solution1.png" alt="stratégie de projet" />
            <p>Nous vous trouvons de nouveaux insights scientifiques pour construire des concepts innovants et disruptifs</p>
          </div>
          <div className="nosSolutions-card">
            <img src="/images_low/solution2.png" alt="stratégie de projet" />
            <p>Nous vous aidons à traduire ces insights scientifiques en concepts marketing aux allégations innovantes, véritables et vérifiables</p>
          </div>
          <div className="nosSolutions-card">
            <img src="/images_low/solution3.png" alt="stratégie de projet" />
            <p>Nous vous accompagnons dans la construction de briefs marketing ancrés dans la science pour des résultats optimisés</p>
          </div>
          <div className="nosSolutions-card">
            <img src="/images_low/solution4.png" alt="stratégie de projet" />
            <p>Nous vous aidons à construire du contenu scientifique unique et authentique, également à destinations des professionnels de santé</p>
          </div>
          <div className="nosSolutions-card">
            <img src="/images_low/profile.png" alt="stratégie de projet" />
            <p>Nous trouvons des solutions pour éduquer votre consommateur et votre prescripteur à l'expertise de votre produit</p>
          </div>
        </div>
        <Link to="/offre_acteurs_marketing"
          className="action"
          onClick={scrollToTop}
        >
          Voir nos offres<i className="fas fa-arrow-right"></i>
        </Link>
      </section>

      <section id="nosPartenaires" className="partenaire_porteur_projet">
        <h3>Nos Partenaires</h3>
        <h4>Ils vous accompagnent aussi</h4>
        <p className="sous-texte">Chez Le Catalyst, nous croyons fermement à la valeur de la collaboration et à la synergie des compétences. C'est pourquoi nous travaillons en étroite collaboration avec un réseau de freelances et entreprises talentueux, chacun apportant une expertise spécifique et complémentaire à notre équipe. Que ce soit un copywriter spécialisé dans la communication scientifique, un expert en sourcing d'actifs, du pilotage d'étude clinique ou un professionnel de la réglementation, nous les avons sélectionnés pour leurs compétences qui complémentent les nôtres et leur capacité à répondre aux besoins variés de nos clients. Tous partagent notre engagement à créer un lien entre la science et le marketing, contribuant ainsi à offrir des solutions adaptées et efficaces pour nos clients.</p>
        <div className="nosPartenaires">
          <div>
            <img src="/images/experts/Camille_Andrieu.jpg" alt="Camille ANDRIEU" />
            <p className="name">Camille ANDRIEU</p>
            <p className="description">Experte Rédaction</p>
            <hr className="line" />
            <p>« Science et marketing s’enrichissent mutuellement : par sa vérité, la science donne au marketing son assise, tandis que le marketing sublime la science dans l’application de son utilité. »</p>
            <a href="https://www.camilleandrieu-redaction.com/" target="_blank" rel="noopener noreferrer">www.camilleandrieu-redaction.com<i className="fa-solid fa-arrow-up-right-from-square"></i></a>
          </div>
          <div>
            <img src="/images/experts/Camille_Bassier.jpg" alt="Camille BASSIER" />
            <p className="name">Camille BASSIER</p>
            <p className="description">Experte Règlementation</p>
            <hr className="line" />
            <p>« La science est le pilier de l'efficacité des produits, le marketing les enveloppe d'une histoire captivante pour nos consommateurs, le tout sous l'œil vigilant de la réglementation pour assurer la sécurité. »</p>
            <a href="https://www.insidecosmetics.fr/" target="_blank" rel="noopener noreferrer">www.insidecosmetics.fr<i className="fa-solid fa-arrow-up-right-from-square"></i></a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AccompagnementActeursMarketing;
