import React from 'react';

const Footer = () => {
  return (
    <footer id="footer">
      <a href="https://www.linkedin.com/company/lecatalyst/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
      <p>© All rights reserved - Le Catalyst 2024</p>
      <div className="legal-link">
        <a href="/mentions_legales" target="_blank">Mentions légales</a>
      </div>
    </footer>
  );
};

export default Footer;
