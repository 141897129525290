import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

const Home = () => {
  const location = useLocation();
  const [offer, setOffer] = useState('-');
  const [initialLoad, setInitialLoad] = useState(true);

  // Extract the offer name from the query parameters
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const offerName = queryParams.get('offer');
    if (offerName) {
      setOffer(offerName);
    }
  }, [location.search]);

  const submitForm = () => {
    const formElement = document.getElementById('contactForm');
    const formData = new FormData(formElement);

    console.log('FormData Entries:', Array.from(formData.entries()));

    fetch('/sendmail.php', {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        console.log('Response Status:', response.status);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.text();
      })
      .then((data) => {
        console.log('Response Data:', data);
        if (data.includes('success')) {
          displayToast('Message envoyé avec succès !');
        } else {
          displayToast("Le message n'a pas pu être envoyé, merci de rééssayer.", true);
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        displayToast("Le message n'a pas pu être envoyé, merci de rééssayer.", true);
      });
  };

  const displayToast = (message, isError = false) => {
    const toast = document.createElement('div');
    toast.style.position = 'fixed';
    toast.style.bottom = '2rem';
    toast.style.left = '50%';
    toast.style.transform = 'translateX(-50%)';
    toast.style.backgroundColor = isError ? '#005897' : '#4BB543';
    toast.style.color = 'white';
    toast.style.padding = '1rem 2rem';
    toast.style.borderRadius = '15px';
    toast.style.zIndex = '1000';
    toast.innerText = message;

    document.body.appendChild(toast);

    setTimeout(() => {
      document.body.removeChild(toast);
    }, 3000);
  };

  // Function to scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
  };

  useEffect(() => {
    // Scroll to the top on initial load
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden'; // Disable scrolling

    // Re-enable scrolling after 2 seconds
    setTimeout(() => {
      document.body.style.overflow = 'auto';
    }, 2000);

    // Function to handle hash changes
    const handleHashChange = () => {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Scroll to the section specified in the hash if it exists
    if (location.hash) {
      handleHashChange();
    }

    // Listen for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Check if the device is a touchscreen
    const isTouchscreen = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    if (isTouchscreen) {
        // Add a class to the body if it is a touchscreen
        document.body.classList.add('touchscreen');
    }

    // Only show the loader on initial load
    if (initialLoad) {
      const activateBubbleAnimation = () => {
        const bubble1 = document.querySelector('#bubble1');
        const div = document.querySelector('#bubble1 div');
        const title = document.querySelector('#title');
        const subtitle = document.querySelector('#subtitle');
        if (bubble1) {
          bubble1.classList.add('animate-bubble1');
          div.classList.add('animate-div');
          title.classList.add('animate-title');
          subtitle.classList.add('animate-subtitle');
        }
      };

      activateBubbleAnimation();

      // Ensure the loader is displayed for at least 3 seconds
      setTimeout(() => {
        // Add the fade-out class to the loader
        const loader = document.getElementById('loader');
        document.querySelector('nav').style.display = 'block';
        loader.classList.add('fade-out');

        // Hide the loader after the fade-out animation completes
        setTimeout(() => {
          loader.style.display = 'none';

          // Activate other animations after the loader fades out
          const activateAnimations = () => {
            // Your other animations
          };

          activateAnimations();

          const submitButton = document.querySelector('.submit-btn');
          if (submitButton) {
            submitButton.addEventListener('click', submitForm);
          }

          const animateTextOnView = () => {
            const targets = document.querySelectorAll('.animate-on-view');
            const observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  entry.target.classList.add('animate');
                  observer.unobserve(entry.target);
                }
              });
            }, {
              threshold: 0.1 // Trigger when 10% of the element is visible
            });

            targets.forEach((target) => {
              observer.observe(target);
            });
          };

          const appearOnView = () => {
            const targets = document.querySelectorAll('.appear-on-view');
            const observer = new IntersectionObserver((entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  entry.target.classList.add('appear');
                  observer.unobserve(entry.target);
                }
              });
            }, {
              threshold: 0.1 // Trigger when 10% of the element is visible
            });

            targets.forEach((target) => {
              observer.observe(target);
            });
          };

          animateTextOnView();
          appearOnView();

          return () => {
            if (submitButton) {
              submitButton.removeEventListener('click', submitForm);
            }
          };
        }, 1000); // Match this duration with the fade-out animation duration

      }, 1200); // Delay of 3 seconds before hiding the loader

      // Mark the initial load as completed
      setInitialLoad(false);
    }

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [location, initialLoad, submitForm]);

  return (
    <div className="body-content">
      {/* Hidden SEO content */}
      <div className="visually-hidden">
        <h1>Le Catalyst - Expert en Communication Scientifique pour les Marques Cosmétique et de la Beauté</h1>
        <h2>Stratégies de Marque et Solutions Innovantes pour la Beauté</h2>
        <h3>
          Le Catalyst est une agence de communication spécialisée en valorisation scientifique, offrant une expertise dans la stratégie de marque et la communication d'entreprise pour l'industrie de la beauté. Nous développons des produits innovants en collaborant étroitement avec les porteurs de projets, les acteurs marketing, et les décisionnaires de marques beauté. Nos services incluent l'accompagnement sur mesure, l'offre de solutions adaptées, et l'intégration d'insights scientifiques pour renforcer votre positionnement sur le marché.
        </h3>
        <p>
          Découvrez nos <Link to="/accompagnement_porteur">offres pour les porteurs de projets cosmétiques</Link>, conçues pour maximiser l'impact de vos produits sur le marché. Pour les <Link to="/accompagnement_acteurs_marketing">acteurs marketing de la beauté</Link>, nous proposons des stratégies marketing axées sur la science, permettant une communication claire et efficace. Les <Link to="/accompagnement_decisionnaires">décisionnaires de marques beauté</Link> bénéficient de notre expertise scientifique pour valoriser leurs produits innovants et maintenir leur position de leader.
        </p>
        <p>
          Nos <Link to="/engagements">engagements</Link> garantissent une approche transparente et rigoureuse, vous assurant un partenariat de confiance pour toutes vos urgences internes et besoins de communication. Que vous recherchiez des solutions de communication, une stratégie de marque, ou une valorisation scientifique, Le Catalyst est votre partenaire expert pour une communication percutante et scientifique.
        </p>
        <p>
          Mots-clés : Communication scientifique, Agence de communication, Stratégie de marque, Expertise scientifique, Produits innovants, Insights scientifiques, Communication d'entreprise, Urgences internes, Solutions de communication, Valorisation scientifique, beauté, marketing, valorisation, scientifique, lecatalyst, le catalyst, accompagnement, aide, décisionnaires, porteurs de projets, acteurs marketing, offre, science.
        </p>
      </div>
      <section id="nousConnaitre">
        <div id="mainTitle">
          <div id="loader" className="loader">
            <div className="loading-bubble"></div>
            <p className="loading-text">Chargement...</p>
          </div>
          <div id="bubble1"><div>LE</div></div>
          <div className="title">
            <div id="title"><span>LE</span> CATALYST</div>
            <h2 id="subtitle">PARTENAIRE EXPERT EN VALORISATION SCIENTIFIQUE</h2>
          </div>
        </div>
        <i className="fa fa-angle-down" id="arrowDown"></i>
      </section>

      <section id="notreMission">
        <div className="notreMission">
          <h3>Notre mission</h3>
          <h4 className="animate-on-view">
            <span>Développez</span> <span><b>une</b></span> <span><b>marque</b></span> <span><b>cosmétique</b></span> <span>authentique</span> <span>aux</span> <span>allégations</span> <span>marketing</span> <span><b>transparentes</b></span> <span>et</span> <span><b>scientifiquement</b></span> <span><b>prouvées</b></span>
          </h4>
          <div className="notreMission-h appear-on-view">
            <img src="images_low/mission1.png" alt="notre mission" />
            <div className="text">
              <h5>Un équilibre entre <b>science et marketing</b></h5>
              <p>Nous croyons en une industrie de la beauté où la science et le marketing s'équilibrent parfaitement. La science est le socle de l'innovation, tandis que le marketing transmet cette vérité scientifique avec puissance et clarté. Nous vous aidons à éliminer les frustrations liées à la dilution de la science et apporter de la transparence et de l'authenticité à chaque produit de beauté, où chaque innovation est une promesse tenue.</p>
            </div>
          </div>
        </div>
      </section>
      
      <section id="nosOffres">
        <h3>Nos offres</h3>
        <h4>Découvrez nos offres sur mesure</h4>
        <p className="presentation">De l'élaboration de stratégies réactives face à des tendances au développement de concepts scientifiques, nous établissons ensemble vos besoins stratégiques lors d'une prise de brief gratuite. Nous vous envoyons ensuite un devis qui s'appuie sur un taux journalier en fonction de la complexité de la mission.</p>
        <div className="offres appear-on-view">
          <div className="offre-container">
            <div className="offre-front">
              <img src="images/porteur-projet.png" alt="Porteurs de projets" />
              <h5>Pour les porteurs de projets cosmétiques</h5>
              <div className="line"></div>
              <p>Vous êtes en cours de création de votre entreprise, vous venez de vous lancer, travaillez seul-e ou sans équipe dédiée, l'industrie de la beauté est nouvelle pour vous, et vous cherchez un prestataire pour vous guider dans vos processus de développement de marque et de produit.</p>
              <button className="action">
                <Link to="/accompagnement_porteur" className="action-link" onClick={scrollToTop}>
                  Votre accompagnement<i className="fas fa-arrow-right"></i>
                </Link>
              </button>
            </div>
          </div>
          
          <div className="offre-container">
            <div className="offre-front">
              <img src="images/acteur.png" alt="Acteurs de marketing beauté" />
              <h5>Pour les acteurs de marketing beauté</h5>
              <div className="line"></div>
              <p>Vous êtes chef de projet marketing ou à la tête d’une équipe marketing, et vous cherchez un accompagnement externe pour atteindre les objectifs fixés par votre direction et/ou répondre aux attentes du marché.</p>
              <button className="action">
                <Link to="/accompagnement_acteurs_marketing" className="action-link" onClick={scrollToTop}>
                  Votre accompagnement<i className="fas fa-arrow-right"></i>
                </Link>
              </button>
            </div>
          </div>
          
          <div className="offre-container">
            <div className="offre-front">
              <img src="images/marque.png" alt="Décisionnaires de marques beauté" />
              <h5>Pour les décisionnaires de marques beauté</h5>
              <div className="line"></div>
              <p>Vous êtes un acteur décisionnaire d’une marque cosmétique, à la tête d’un pôle de direction, et vous cherchez une expertise scientifique pour consolider votre positionnement et votre réputation sur le marché.</p>
              <button className="action">
                <Link to="/accompagnement_decisionnaires" className="action-link" onClick={scrollToTop}>
                  Votre accompagnement<i className="fas fa-arrow-right"></i>
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="line"></div>

        <div className="accompagnement">
          <img src="images/accompagnement.png" alt="Accompagnement" />
          <div className="text">
            <h6><b>Un accompagnement personnalisé</b> pour les acteurs de l’industrie de la beauté</h6>
            <p>Des missions de consulting pour intégrer la science dans vos stratégies marketing et développements de produit. Nous accompagnons votre marque beauté et vos projets de l'élaboration de la stratégie à l'activation de vos produits avec des livrables clefs en main.</p>
          </div>
        </div>
      </section>

      <section id="tags">
        <ul>
          <li>Brief marketing</li>
          <li>Book scientifique</li>
          <li>Contenus scientifiques</li>
          <li>Stratégie de communication scientifique</li>
          <li>Evènementiel scientifique</li>
          <li>Territoire scientifique</li>
          <li>Insights scientifiques</li>
          <li>Concepts scientifiques</li>
          <li>Allégations produit</li>
        </ul>
      </section>

      <section id="nosExperts">
        <h3>Nos Experts</h3>
        <h4 className='animate-on-view'><span><b>Les experts</b></span> <span>derrière notre approche</span></h4>
        <p className='appear-on-view'>Deux co-fondatrices aux expertises complémentaires : Marie, spécialiste en science et marketing, et Nathalie, experte en communication. Leur synergie unique garantit une intégration fluide de la <b>science vérifiable</b> et du <b>marketing authentique</b>, créant ainsi une valeur inégalée pour transformer votre marque beauté.</p>
        <div className='appear-on-view'>
          <div className="expert1">
            <img src="images/experts/Marie_Braun2.jpg" alt="Marie Braun" />
            <div>
              <h5>Marie Braun</h5>
              <p>Ingénieure en Biologie avec une expérience riche en marketing produit et communication scientifique forgée au sein de grandes marques de l'industrie cosmétique. Elle est votre interlocutrice principale pour la gestion des projets, et sera votre guide pour intégrer la science au cœur des stratégies marketing et des processus de développement.</p>
            </div>
          </div>
        </div>
        <div className='appear-on-view'>
          <div className="expert2">
            <div>
              <h5>Nathalie Boxberger</h5>
              <p>Experte en communication, Nathalie apporte une perspective unique centrée sur le storytelling et la relation client. Elle enrichit notre équipe par sa capacité à penser différemment et à innover, même en dehors du secteur de la beauté. Nathalie guide notre stratégie globale avec sa vision éclairée et remet en question le statu quo, poussant les limites de l'industrie cosmétique.</p>
            </div>
            <img src="images/experts/Nathalie_Boxberger.jpg" alt="Nathalie Boxberger" />
          </div>
        </div>
      </section>

      <section id="avis">
        <h4 className='animate-on-view'><span>Ils nous ont fait</span> <span><b>confiance</b></span></h4>
        <div className="line"></div>
        <p>Nous aspirons à devenir votre partenaire incontournable, synonyme de qualité et de véracité.</p>
        <div className="avis-cards">
          {/* <div className="avis-card">
            <div className="avis-card-top">
              <p className="avis-nom">Liya</p>
              <div className="line"></div>
              <p className="avis-titre">Porteur de projet cosmétique</p>
              <div className="avis-stars"></div>
              <img className="stars" src="images/stars.png" alt="avis" />
              <p className="avis-text">"D’une manière très réactive et avec beaucoup de méthodologie, Marie Braun a pu nous fournir les références de publications scientifiques qui se situent dans le champ de nos allégations. Ces exemples précieux élargissent notre spectre car ils nous mettent sur une piste nouvelle et complémentaire pour asseoir nos justifications. Nous avons également particulièrement apprécié la partie livrable: les conclusions écrites avec les suggestions pour la stratégie à adopter avec des hypothèses probantes. Après cette expérience, Catalyst nous paraît comme partenaire incontournable quand on souhaite travailler les fondations solides d’une marque innovante. Je vous recommande vivement Catalyst pour vous accompagner dans la structuration de votre travail sur les justifications scientifiques et/ou le lancement de vos partenariats scientifiques. Je remercie Marie de son éclairage limpide sur le sujet et son grand professionnalisme."</p>
            </div>
            <p className="avis-formule">Rationnel scientifique</p>
          </div> */}
          <div className="avis-card">
            <div className="avis-card-top">
              <p className="avis-nom">Maïra</p>
              <div className="line"></div>
              <p className="avis-titre">Fondatrice Startup Beauté</p>
              <img className="stars" src="images/stars.png" alt="avis" />
              <p className="avis-text">"J'ai sollicité l'agence Le Catalyst, et plus particulièrement l'expertise de Marie. J'ai été agréablement surprise par son professionnalisme et son implication dans les échanges tant avec moi qu'avec mon laboratoire. J'ai grandement apprécié son écoute attentive et sa disponibilité. Ses conseils étaient extrêmement pertinents. C'est sans hésitation que je ferai de nouveau appel à elle pour m'accompagner dans le développement de ma marque."</p>
            </div>
            <p className="avis-formule">Accompagnement formulation</p>
          </div>
          <div className="avis-card">
            <div className="avis-card-top">
              <p className="avis-nom">Liya</p>
              <div className="line"></div>
              <p className="avis-titre">Fondatrice Startup Beauté</p>
              <img className="stars" src="images/stars.png" alt="avis" />
              <p className="avis-text">[...] "D'une manière très réactive et avec beaucoup de méthodologie, Marie Braun a pu nous fournir les références de publications scientifiques qui se situent dans le champ de nos allégations. Ces exemples précieux élargissent notre spectre car ils nous mettent sur une piste nouvelle et complémentaire pour asseoir nos justifications. Nous avons également particulièrement apprécié la partie livrable: les conclusions écrites avec les suggestions pour la stratégie à adopter avec des hypothèses probantes. Après cette expérience, Le Catalyst nous paraît comme un partenaire incontournable quand on souhaite travailler les fondations solides d'une marque innovante." [...]</p>
            </div>
            <p className="avis-formule">Rationnel scientifique</p>
          </div>
          <div className="avis-card">
            <div className="avis-card-top">
              <p className="avis-nom">Vanessa</p>
              <div className="line"></div>
              <p className="avis-titre">Fondatrice Womanology</p>
              <img className="stars" src="images/stars.png" alt="avis" />
              <p className="avis-text">"L'expérience de Marie et ses compétences transversales, l'écoute et l'échange bienveillant et personnalisé, une compréhension immédiate de ma marque et la réelle volonté de respecter son ADN tout en s'adaptant aux moyens et possibilités réduites d'une startup, la réactitvité et la disponibilité, les idées créatives aussi bien que pratiques, l'implication à 200%, ce qui donne l'impression que Marie fait partie de ma société tellement son enthousiasme et soutien font partie de sa démarche."</p>
            </div>
            <p className="avis-formule">Marketing Scientifique</p>
          </div>
        </div>
        <button className="action">
          <Link to="/engagements" className="action-link">
            Nos engagements<i className="fas fa-arrow-right"></i>
          </Link>
        </button>
      </section>

      <section id="nousContacter">
        <div>
          <h3>Nous contacter</h3>
          <h4 className='animate-on-view'><span>Prêt à faire de la</span> <span><b>science votre nouvelle alliée ?</b></span></h4>
          <p className='appear-on-view'>Nous aspirons à devenir votre partenaire incontournable, synonyme de qualité et de véracité. <br /> Une urgence ? Appelez nous directement :  
          <span id="phone-number"> 
            &#43;&#51;&#51;&#54; &#55;&#52; &#56;&#49; &#55;&#52; &#50;&#54;
          </span></p>
        </div>
        
        <form className="form-container appear-on-view" id="contactForm" method="post">
      <div className="input-group">
        <div className="input-wrapper">
          <label htmlFor="nom">Votre nom</label>
          <input type="text" name="nom" id="nom" placeholder="John Doe" />
        </div>
        <div className="input-wrapper">
          <label htmlFor="email">Votre adresse email</label>
          <input type="email" name="email" id="email" placeholder="adresse@email.com" />
        </div>
      </div>
      <div className="input-group">
        <div className="input-wrapper">
          <label htmlFor="status">Je suis</label>
          <select name="status" id="status">
            <option value="autre">-</option>
            <option value="porteur">Porteur de projets cosmétique</option>
            <option value="acteur-marketing">Acteur marketing de la beauté</option>
            <option value="decisionnaire">Décisionnaire de marque beauté</option>
          </select>
        </div>
        <div className="input-wrapper">
          <label htmlFor="offre">Une offre vous intéresse ?</label>
          <select name="offre" id="offre" value={offer} onChange={(e) => setOffer(e.target.value)}>
            <option value="-">-</option>
            <option value="Beauty Booster Pack">Beauty Booster Pack</option>
            <option value="Beauty Starter Pack">Beauty Starter Pack</option>
            <option value="Mission beauté personnalisée">Mission beauté personnalisée</option>
            <option value="Soutien continu">Soutien continu</option>
            <option value="Support Scientifique Stratégique">Support Scientifique Stratégique</option>
            <option value="Support Scientifique Opérationnel">Support Scientifique Opérationnel</option>
            <option value="Offre SOS">Offre SOS</option>
          </select>
        </div>
      </div>
      <div className="input-wrapper">
        <label htmlFor="objet">Votre message</label>
        <input className="input-object" type="text" name="objet" id="objet" placeholder="Objet" />
      </div>
      <div className="input-wrapper">
        <textarea name="message" id="message" placeholder="Votre message" rows="4"></textarea>
      </div>
      <button type="button" className="action submit-btn">Envoyer mon message</button>
    </form>
      </section>        
    </div>
  );
};

export default Home;
