import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname + location.hash);

  const margin = 150; // Adjust this value to activate links sooner

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      sections.forEach(section => {
        const rect = section.getBoundingClientRect();
        if (section.id === 'avis' && rect.top <= margin && rect.bottom >= margin) {
          setActiveLink('/#avis');
        } else if (rect.top <= margin && rect.bottom >= margin) {
          setActiveLink(location.pathname + '#' + section.id);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location]);

  useEffect(() => {
    // Update active link when location changes
    if (location.hash === '#avis') {
      setActiveLink('/#avis');
    } else {
      setActiveLink(location.pathname + location.hash);
    }
  }, [location]);

  const isActive = (path) => {
    return activeLink === path;
  };

  const isDropdownActive = (paths) => {
    return paths.some(path => activeLink.startsWith(path));
  };

  // Function to toggle the burger menu
  const toggleBurgerMenu = () => {
    const nav = document.querySelector('nav ul');
    nav.classList.toggle('show');
  };

  // Function to hide the navbar
  const hideNavbar = () => {
    const nav = document.querySelector('nav ul');
    nav.classList.remove('show');
  };

  // Attach hideNavbar to each link
  const handleLinkClick = (event) => {
    // Scroll to the top before navigating
    window.scrollTo(0, 0);
    hideNavbar();
  };

  // Function to set the active state for nav links based on intersection
  const setNavLinksAndBackground = () => {
    const navLinks = document.querySelectorAll('nav ul li a');
    const sections = document.querySelectorAll('.body-content section');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        navLinks.forEach(link => {
          if (link.getAttribute('href').substring(1) === entry.target.id) {
            if (entry.isIntersecting) {
              link.classList.add('active');
            } else {
              link.classList.remove('active');
            }
          }
        });
      });
    }, { threshold: 0.2 });

    sections.forEach(section => {
      observer.observe(section);
    });
  };

  useEffect(() => {
    setNavLinksAndBackground();
  }, []);

  return (
    <nav>
      <div className="burger-menu" onClick={toggleBurgerMenu}>
        <i className="fa fa-bars"></i>
      </div>
      <Link to="/" id="home">
        <i className="fa fa-home"></i>
      </Link>
      <ul>
        <li id="accueil"><Link to="/" className={isActive('/') ? 'active' : ''} onClick={handleLinkClick}>Accueil</Link></li>
        <li><Link to="/#notreMission" className={isActive('/#notreMission') ? 'active' : ''} onClick={handleLinkClick}>Notre mission</Link></li>
        <li className={`dropdown ${isDropdownActive(['/accompagnement_porteur', '/accompagnement_acteurs_marketing', '/accompagnement_decisionnaires']) ? 'active' : ''}`}>
          <a href="#">Nos Accompagnements</a>
          <ul className="dropdown-content">
            <li><Link to="/accompagnement_porteur" className={isActive('/accompagnement_porteur') ? 'active' : ''} onClick={handleLinkClick}>Porteurs de projets cosmétiques</Link></li>
            <li><Link to="/accompagnement_acteurs_marketing" className={isActive('/accompagnement_acteurs_marketing') ? 'active' : ''} onClick={handleLinkClick}>Acteurs marketing de la beauté</Link></li>
            <li><Link to="/accompagnement_decisionnaires" className={isActive('/accompagnement_decisionnaires') ? 'active' : ''} onClick={handleLinkClick}>Décisionnaires de marques beauté</Link></li>
          </ul>
        </li>
        <li className={`dropdown ${isDropdownActive(['/offre_porteurs_projets', '/offre_acteurs_marketing', '/offre_decisionnaires']) ? 'active' : ''}`}>
          <a href="#">Nos Offres</a>
          <ul className="dropdown-content">
            <li><Link to="/offre_porteurs_projets" className={isActive('/offre_porteurs_projets') ? 'active' : ''} onClick={handleLinkClick}>Porteurs de projets cosmétiques</Link></li>
            <li><Link to="/offre_acteurs_marketing" className={isActive('/offre_acteurs_marketing') ? 'active' : ''} onClick={handleLinkClick}>Acteurs marketing de la beauté</Link></li>
            <li><Link to="/offre_decisionnaires" className={isActive('/offre_decisionnaires') ? 'active' : ''} onClick={handleLinkClick}>Décisionnaires de marque beauté</Link></li>
          </ul>
        </li>
        <li><Link to="/#nosExperts" className={isActive('/#nosExperts') ? 'active' : ''} onClick={handleLinkClick}>Nos experts</Link></li>
        <li><Link to="/engagements" className={isActive('/engagements') || isActive('/#avis') ? 'active' : ''} onClick={handleLinkClick}>Nos engagements</Link></li>
        <li><Link to="/#nousContacter" className={isActive('/#nousContacter') ? 'active' : ''} onClick={handleLinkClick}>Nous contacter</Link></li>
      </ul>
    </nav>
  );
}

export default Navbar;
