import React from 'react';
import { useNavigate } from 'react-router-dom';

const OffrePorteursProjets = () => {
  const navigate = useNavigate();

  // Function to navigate and scroll with offer name
  const navigateAndScroll = (offerName) => {
    navigate(`/?offer=${encodeURIComponent(offerName)}#nousContacter`);
  };

  return (
    <div className="body-content offres-porteurs">
      <section className="offre">
        <h3>Nos offres - Porteurs de projets</h3>
        <h4>Démarrage réussi</h4>
        <h5>Packs & Abonnements pour les porteurs de projets cosmétiques</h5>
        <div className="intro-offre">
          <img src="/images_low/ingredient2-no-bg.png" alt="Offre 1" />
          <div className="text">
            <h6>Nos offres d'entrée</h6>
            <p>Vous souhaitez tester la collaboration ? Vous avez peu de budget ? Ces offres intègrent des heures de consulting et un projet opérationnel pour vous permettre un retour sur investissement rapide, avec des livrables clefs en main.</p>
          </div>
        </div>
        <div className="offres-cards">
          <div className="offre-card">
            <h5>Beauty Starter Pack</h5>
            <h6>Déconstruire un concept</h6>
            <p><i className="fa-solid fa-check"></i>3h de consulting</p>
            <p style={{ color: '#2D9BF0' }}><i className="fa-solid fa-check"></i>Rationnel scientifique sur une allégation</p>
            <p className="price">350€ HT</p>
            <button className="action" onClick={() => navigateAndScroll('Beauty Starter Pack')}>
              Parlons-en<i className="fas fa-arrow-right"></i>
            </button>
          </div>
          <div className="offre-card">
            <h5>Beauty Booster Pack</h5>
            <h6>Construire une base scientifique solide</h6>
            <p><i className="fa-solid fa-check"></i>5h de consulting</p>
            <p style={{ color: '#2D9BF0' }}><i className="fa-solid fa-check"></i>Rationnel scientifique sur un concept ou sur le projet au global</p>
            <p className="price">1500€ HT</p>
            <button className="action" onClick={() => navigateAndScroll('Beauty Booster Pack')}>
              Parlons-en<i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </section>

      <section className="offre2">
        <div className="text">
          <h4>Mission beauté personnalisée</h4>
          <p>Nous établissons ensemble vos besoins lors d'une prise de brief gratuite. Nous vous envoyons ensuite un devis qui s'appuie sur un taux journalier en fonction de la complexité de la mission.</p>
          <div className="price-action">
            <p className="price">TJ 800 à 1 100 € HT</p>
            <button className="action" onClick={() => navigateAndScroll('Mission beauté personnalisée')}>
              Parlons-en<i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <img src="/images_low/offre2.png" alt="Offre 2" />
      </section>

      <section className="soutien">
        <h4>Soutien continu</h4>
        <p>Un partenaire expert en communication scientifique à portée de main en réunion.</p>
        <table>
          <tbody>
            <tr>
              <td>
                <img src="/images_low/abo1.png" alt="Abonnement 1 mois" />
              </td>
              <td>
                <h5>ABONNEMENT 1 MOIS</h5>
                <p>1h/semaine cumulable dans le mois</p>
              </td>
              <td className="price">500 € / MOIS</td>
            </tr>
            <tr>
              <td>
                <img src="/images_low/abo3.png" alt="Abonnement 3 mois" />
              </td>
              <td>
                <h5>ABONNEMENT 3 MOIS</h5>
                <p>1h/semaine cumulable dans le mois</p>
              </td>
              <td className="price">450 € / MOIS</td>
            </tr>
            <tr>
              <td>
                <img src="/images_low/abo12.png" alt="Abonnement 12 mois" />
              </td>
              <td>
                <h5>ABONNEMENT 12 MOIS</h5>
                <p>1h/semaine cumulable dans le mois</p>
              </td>
              <td className="price">400 € / MOIS</td>
            </tr>
          </tbody>
        </table>
        <button className="action" onClick={() => navigateAndScroll('Soutien continu')}>
          Parlons-en<i className="fas fa-arrow-right"></i>
        </button>
      </section>
    </div>
  );
};

export default OffrePorteursProjets;