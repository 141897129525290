import React from 'react';
import { useNavigate } from 'react-router-dom';

const Engagements = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    // Function to navigate and scroll
    const navigateAndScroll = () => {
      navigate('/'); // Navigate to the main page
      setTimeout(() => {
        const targetElement = document.getElementById('nousContacter');
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'auto' });
        }
      }, 100); // Delay to ensure page navigation occurs before scrolling
    };

  return (
    <div className="body-content">
      <section id="nosEngagements">
        <h3>Nos Engagements</h3>
        <h4>Notre engagement envers vous</h4>
        <p className="sous-texte">Chez Le Catalyst, nous croyons fermement en l'importance de bâtir des relations solides, basées sur la confiance, la transparence et l'engagement mutuel. Travailler à nos côtés signifie choisir un partenaire de confiance qui comprend vos besoins et s'investit pleinement dans la réussite de votre projet. </p>
        <div className="engagements">
          {/* Engagement 1 */}
          <div className="engagement-content">
            <img src="/images_low/partenaire.png" alt="Un partenaire de confiance" />
            <div className="engagement-text">
              <img src="/images_low/solution1.png" alt="stratégie de projet" />
              <h5>Un partenaire de confiance</h5>
              <p>Nous établissons des relations authentiques et transparentes avec nos clients, basées sur la confiance mutuelle et la transparence.</p>
            </div>
          </div>
          {/* Engagement 2 */}
          <div className="engagement-content">
            <div className="engagement-text">
              <img src="/images_low/solution1.png" alt="stratégie de projet" />
              <h5>Des solutions efficaces</h5>
              <p>Nous souhaitons offrir des solutions concrètes, structurées et rapides vous garantissant des résultats tangibles et pertinents, sans perte de temps.</p>
            </div>
            <img src="/images_low/efficacite.png" alt="Efficacité" />
          </div>
          {/* Engagement 3 */}
          <div className="engagement-content">
            <img src="/images_low/collaboration.png" alt="Collaboration active" />
            <div className="engagement-text">
              <img src="/images_low/solution1.png" alt="stratégie de projet" />
              <h5>Un collaboration active</h5>
              <p>Nous sommes investis dans chaque projet, nous comprenons les défis auxquels vous êtes confrontés et nous travaillerons activement à les surmonter ensemble. De plus, nous pouvons favoriser le déplacement pour venir travailler directement avec vous, renforçant ainsi notre engagement à vos côtés.</p>
            </div>
          </div>
          {/* Engagement 4 */}
          <div className="engagement-content">
            <div className="engagement-text">
              <img src="/images_low/solution1.png" alt="stratégie de projet" />
              <h5>Une passion à partager</h5>
              <p>Notre équipe est animée par une passion contagieuse pour notre belle industrie. En tant que partenaire, nous allons au-delà de la simple collaboration sur vos projets ; nous avons le désir de transmettre notre savoir-faire, vous permettant ainsi de mieux appréhender cet équilibre entre science et marketing.</p>
            </div>
            <img src="/images_low/passion.png" alt="Passion" />
          </div>
        <button className="action" onClick={navigateAndScroll}>
            Parlons-en<i className="fas fa-arrow-right"></i>
        </button>
        </div>
      </section>
    </div>
  );
};

export default Engagements;
