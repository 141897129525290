import React from 'react';

const MentionsLegales = () => {
  return (
    <div className="mentions_legales">
      <div className="content">
        <h1>Mentions légales</h1>
        <p>Le Catalyst</p>
        <hr />
        <h2>IDENTIFICATION DE L'ÉDITEUR</h2>
        <p>Le site Le Catalyst (ci-après « le Site »), accessible à l’adresse <a href="https://LeCatalyst.fr">LeCatalyst.fr</a> est édité par la société Le Catalyst (ci-après « l'Éditeur »), SARL au capital de 7000.0 euros, dont le siège social est situé 31 Boulevard de Grenelle, 75015 Paris, France. Il est immatriculé au RCS de Paris sous le numéro 982137705. TVA intracommunautaire n° FR 06 982137705</p>
        <p>L'Éditeur peut être contacté à l’adresse mail <a href="mailto:m.braun.catalyst@gmail.com">m.braun.catalyst@gmail.com</a> ou au numéro 06 74 81 74 26.</p>
        <hr />
        <h2>HÉBERGEUR DU SITE INTERNET</h2>
        <p>
          OVH.COM - SAS au capital de 10 174 560 €
          RCS Lille Métropole 424 761 419 00045
          Code APE 2620Z
          N° TVA : FR 22 424 761 419
          Siège social : 2 rue Kellermann - 59100 Roubaix - France
          OVH SAS est une filiale de la société OVH Groupe SA, société immatriculée au RCS de Lille sous le numéro 537 407 926 sise 2, rue Kellermann, 59100 Roubaix.
          Directeur de la publication : Michel Paulin
          Ce site est hébergé par OVH SAS 2 rue Kellermann - 59100 Roubaix - France
        </p>
        <hr />
        <h2>COLLECTE DE DONNÉES PERSONNELLES</h2>
        <p>Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978 modifiée, relative à l'informatique, aux fichiers et aux libertés, l’utilisateur est informé que l'Éditeur procède à la collecte et au traitement de données personnelles, lors de la connexion au Site.</p>
        <p>Les données collectées servent principalement à permettre la mise à disposition de la Plateforme, son optimisation et sa sécurisation afin d’offrir aux utilisateurs un service de la meilleure qualité possible. Des données supplémentaires pourront éventuellement être collectées par l'Éditeur pour permettre la bonne exécution de contrats commerciaux conclus par l’entremise de la Plateforme avec l’utilisateur.</p>
        <p>L’utilisateur est informé qu’il dispose d'un droit d'accès, d'interrogation, de modification et de suppression des informations qui le concernent, à exercer à tout moment auprès de l'Éditeur soit directement sur le Site, soit par courrier postal adressé au 31 Boulevard de Grenelle, 75015 Paris, France soit par e-mail à l’adresse <a href="mailto:m.braun.catalyst@gmail.com">m.braun.catalyst@gmail.com</a>.</p>
        <p>Les informations recueillies pourront éventuellement être partagées à des tiers résidant dans l’Union Européenne dans les cas suivants :</p>
        <ul>
          <li>Lorsque l’utilisateur publie, dans une zone de commentaire, des informations accessibles au public ;</li>
          <li>Lorsque l’utilisateur y consent expressément ;</li>
          <li>Lorsque la loi l’exige ou afin de coopérer à une enquête judiciaire à la demande exprès d’un détenteur de l’autorité publique ;</li>
          <li>Pour l’exécution de prestations commerciales pour lesquelles est rendue nécessaire la coopération d’un tiers, notamment dans le cas où l’Éditeur choisirait de sous-traiter certaines de ses prestations par l’entremise de Services Tiers.</li>
        </ul>
        <p>Lorsque certaines informations sont obligatoires pour accéder à des fonctionnalités spécifiques du Site, l'Éditeur indiquera ce caractère obligatoire au moment de la saisie des données.</p>
        <p>Les données personnelles collectées ne seront conservées que le temps nécessaire pour permettre la bonne utilisation du Site, empêcher les fraudes et abus, et satisfaire aux obligations légales et réglementaires de l'Éditeur concernant la gestion du Site.</p>
        <hr />
        <h2>COOKIES</h2>
        <p>Le Site peut avoir recours à la technique des "cookies" telle que définie par la CNIL de la manière suivante :</p>
        <blockquote>
          <p>" Un cookie est un petit fichier stocké par un serveur dans le terminal (ordinateur, téléphone, etc.) d’un utilisateur et associé à un domaine web (c’est-à-dire dans la majorité des cas à l’ensemble des pages d’un même site web). Ce fichier est automatiquement renvoyé lors de contacts ultérieurs avec le même domaine.</p>
          <p>Les cookies ont de multiples usages : ils peuvent servir à mémoriser votre identifiant client auprès d'un site marchand, le contenu courant de votre panier d'achat, la langue d’affichage de la page web, un identifiant permettant de tracer votre navigation à des fins statistiques ou publicitaires, etc. "</p>
        </blockquote>
        <p>Information issue du site officiel de la CNIL (<a href="https://www.cnil.fr/fr/glossaire">https://www.cnil.fr/fr/glossaire</a>)</p>
        <p>Ces cookies ont pour objectif de faciliter la navigation de l’utilisateur et d’améliorer la qualité du service proposé, en collectant des informations statistiques et relatives au trafic.</p>
        <p>L’utilisation de ces cookies est portée à la connaissance de l’utilisateur par le biais d’un bandeau lui demandant son consentement. Si l’utilisateur y consent, ce consentement est considéré comme valide pour une durée maximale de treize (13) mois.</p>
        <p>Si l’utilisateur ne consent pas à l’utilisation des cookies, l’Éditeur ne pourra lui garantir une expérience optimale sur le Site.</p>
        <p>Les cookies suivants sont utilisés sur le Site : Google Analytics.</p>
        <hr />
        <h2>RESPECT DE LA PROPRIÉTÉ INTELLECTUELLE</h2>
        <p>Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner le Site, et plus généralement tous les éléments reproduits ou utilisés sur le Site, sont protégés par les lois en vigueur au titre de la propriété intellectuelle.</p>
        <p>Ils sont la propriété pleine et entière de l'Éditeur ou de ses partenaires, sauf mentions particulières. Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans l'accord préalable et écrit de l'Éditeur, sont strictement interdites. Le fait pour l'Éditeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites.</p>
        <p>Seule l'utilisation pour un usage privé dans un cercle de famille est autorisée et toute autre utilisation est constitutive de contrefaçon et/ou d'atteinte aux droits voisins, sanctionnées par Code de la propriété intellectuelle.</p>
        <p>La reprise de tout ou partie de ce contenu nécessite l'autorisation préalable de l'Éditeur ou du titulaire des droits sur ce contenu.</p>
        <hr />
        <h2>LIENS HYPERTEXTES</h2>
        <p>Le Site peut contenir des liens hypertextes donnant accès à d'autres sites web édités et gérés par des tiers et non par l'Éditeur. L'Éditeur ne pourra être tenu responsable directement ou indirectement dans le cas où lesdits sites tiers ne respecteraient pas les dispositions légales.</p>
        <p>La création de liens hypertextes vers le Site ne peut être faite qu'avec l'autorisation écrite et préalable de l'Éditeur.</p>
      </div>

      <div className="separator"></div>
      
      <div className="content">
        <h1>Conditions Générales d’Utilisation</h1>
        <p>Mis à jour le : 23/03/2024</p>
    
        <article>
          <h2>Préambule</h2>
          <p>Le Catalyst est une plate-forme web, disponible à l’adresse lecatalyst.fr (le « Site »).</p>
          <p>Ce Site est édité par Le Catalyst, SARL au capital de 7000.0 euros, ayant son siège social situé au 31 Boulevard de Grenelle, 75015 Paris, France et immatriculé au registre du commerce et des sociétés de Paris sous le numéro d’identification 982137705, (ci-après « l’Editeur »). Le Catalyst est accessible aux utilisateurs (le ou les « Utilisateurs »).</p>
          <p>Le Catalyst est hébergé par OVH, dont le siège social est situé au 2 rue Kellermann - 59100 Roubaix - France. OVH SAS est une filiale de la société OVH Groupe SA, société immatriculée au RCS de Lille sous le numéro 537 407 926 sise 2, rue Kellermann, 59100 Roubaix.</p>
          <p>L’objet des présentes Conditions Générales d’Utilisation (les « Conditions » ou prises dans leur ensemble, le « Contrat ») est de définir les termes et les conditions régissant les relations entre les Utilisateurs et Le Catalyst. En cas de non-respect des termes des présentes Conditions, Le Catalyst se réserve le droit de prendre toute mesure de nature à préserver ses intérêts et notamment à en assurer l'exécution.</p>
          <p>L’accès et l’utilisation du site sont soumis à l’acceptation et au respect des présentes Conditions Générales d’Utilisation.</p>
          <p>L’Éditeur se réserve le droit de modifier, à tout moment et sans préavis, le Site et des services ainsi que les présentes CGU, notamment pour s’adapter aux évolutions du Site par la mise à disposition de nouvelles fonctionnalités ou la suppression ou la modification de fonctionnalités existantes.</p>
          <p>Il est donc conseillé à l’Utilisateur de se référer avant toute navigation à la dernière version des Conditions Générales d’Utilisation accessible à tout moment sur le Site.</p>
          <p>Les présentes Conditions Générales d’Utilisation s’appliquent, en tant que de besoin, à toute déclinaison ou extension du Site sur les réseaux sociaux et/ou communautaires existants ou à venir.</p>
        </article>

        <article>
          <h2>Article 1er : Objet du site</h2>
          <p>Le Site permet à l’Utilisateur d’accéder notamment aux services suivants : Service de conseil en valorisation scientifique destiné au secteur de la beauté (le ou les « Service(s) »).</p>
          <p>Le Catalyst, selon les présentes Conditions, accorde aux Utilisateurs un droit d’accès limité révocable, non exclusif, non cessible aux Services à titre strictement personnel. Toute utilisation de la plate-forme contraire à sa finalité est strictement interdite et constitue un manquement aux présentes dispositions.</p>
        </article>
    
        <article>
          <h2>Article 2 : Accès au site</h2>
          <p>Pour être éligible au Service, l’Utilisateur peut être une personne physique ou morale.</p>
          <p>Le Catalyst se réserve le droit de suspendre ou de refuser un accès d’un ou plusieurs Utilisateurs au Site.</p>
        </article>
    
        <article>
          <h2>Article 3 : Gestion du site</h2>
          <p>L’Editeur met en œuvre des solutions techniques nécessaires afin de permettre un accès continu au site pour l’Utilisateur. Toutefois il se réserve la possibilité de limiter ou suspendre l’accès à tout ou partie du Site à tout moment, et sans préavis. Une telle interruption pourra notamment être rendue nécessaire pour des raisons de maintenances du site ou de son contenu, ou pour toute autre raison jugée nécessaire au bon fonctionnement du site par l’Editeur.</p>
          <p>L’utilisation de la Plateforme requiert une connexion et un navigateur internet. La plateforme est accessible à l’adresse suivante : lecatalyst.fr.</p>
          <p>Tous matériels et logiciels nécessaires à l’accès à la Plateforme et à l’utilisation des Services restent exclusivement à la charge de l’Utilisateur.</p>
        </article>
    
        <article>
          <h2>Article 4 : Contact</h2>
          <p>L’utilisateur a la possibilité de contacter l’Editeur pour toute question ou demande d’information concernant le Site, ou tout signalement de contenu ou d’activités illicites par mail à l’adresse <a href="mailto:m.braun.catalyst@gmail.com">m.braun.catalyst@gmail.com</a>.</p>
        </article>
    
        <article>
          <h2>Article 5 : Utilisation des services de la plateforme</h2>
          <p>Les Utilisateurs s’interdisent :</p>
          <ul>
            <li>de transmettre, publier, distribuer, enregistrer ou détruire tout matériel, en particulier les contenus de Le Catalyst, en violation des lois ou réglementations en vigueur concernant la collecte, le traitement ou le transfert d'informations personnelles ;</li>
            <li>de diffuser des données, informations, ou contenus à caractère diffamatoire, injurieux, obscène, offensant, violent ou incitant à la violence, ou à caractère politique, raciste ou xénophobe et de manière générale tout contenu qui serait contraire aux lois et règlements en vigueur ou aux bonnes mœurs ;</li>
            <li>de référencer ou créer des liens vers tout contenu ou information disponible depuis les sites de Le Catalyst, sauf accord exprès, préalable et écrit de Le Catalyst ;</li>
            <li>d’utiliser des informations, contenus ou toutes données présentes sur le Site afin de proposer un service considéré comme concurrentiel à Le Catalyst ;</li>
            <li>de vendre, échanger ou monnayer des informations, contenus ou données présentes sur la plateforme ou des Service proposés par la Plateforme, sans l’accord exprès et écrit de Le Catalyst ;</li>
            <li>de pratiquer de l’ingénierie inversée (Reverse Engineering), décompiler, désassembler, déchiffrer ou autrement tenter d’obtenir le code source en relation avec toute propriété intellectuelle sous-jacente utilisée pour fournir tout ou partie des Services ;</li>
            <li>d’utiliser des logiciels ou appareils manuels ou automates, robots de codage ou autres moyens pour accéder, explorer, extraire ou indexer toute page du Site ;</li>
            <li>de mettre en danger ou essayer de mettre en danger la sécurité numérique de Le Catalyst. Cela comprend les tentatives de contrôler, scanner ou tester la vulnérabilité du système ou réseau ou de violer des mesures de sécurité ou d’authentification sans une autorisation préalable expresse ;</li>
            <li>de contrefaire ou d’utiliser les produits, les logos, les marques ou tout autre élément protégé par les droits de propriété intellectuel de Le Catalyst ;</li>
            <li>de simuler l’apparence ou le fonctionnement du Site, en procédant par exemple à un effet miroir ;</li>
            <li>de perturber ou troubler, directement ou indirectement Le Catalyst, ou imposer une charge disproportionnée sur l’infrastructure du Site, ou de tenter de transmettre ou d’activer des virus informatiques via ou sur le Site.</li>
          </ul>
          <p>Il est rappelé que les violations de la sécurité du système ou du réseau peuvent conduire à des poursuites civiles et pénales. Le Catalyst vérifie l’absence de telle violation et peut faire appel aux autorités judiciaires pour poursuivre, le cas échéant, des Utilisateurs ayant participé à de telles violations.</p>
          <p>Les Utilisateurs s’engagent à utiliser le Site de manière loyale, conformément à sa finalité et aux dispositions légales, réglementaires, aux présentes Conditions et aux usages en vigueur.</p>
        </article>

        <article>
          <h2>Article 6 : Propriété intellectuelle</h2>
          <p>L’ensemble du contenu du Site, notamment les designs, textes, graphiques, images, vidéos, informations, logos, icônes-boutons, logiciels, fichiers audio et autres appartient à Le Catalyst, lequel est seul titulaire de l’intégralité des droits de propriété intellectuelle y afférents.</p>
          <p>Toute représentation et/ou reproduction et/ou exploitation partielle ou totale des contenus et Services proposés par Le Catalyst, par quelque procédé que ce soit, sans l'autorisation préalable et écrite de Le Catalyst, est strictement interdite et serait susceptible de donner lieu à des poursuites judiciaires.</p>
        </article>
    
        <article>
          <h2>Article 7 : Données à caractère personnelles</h2>
          <p>Toutes les données à caractère personnel dont dispose Le Catalyst sont recueillies légalement et loyalement selon les modalités de la politique de confidentialité accessible à cette adresse : lecatalyst.fr/xxxxxx.</p>
          <p>Ces données sont fournies par les Utilisateurs qui acceptent de manière volontaire et expresse les présentes Conditions autorisant Le Catalyst à traiter, divulguer ou transférer ces données à tout tiers cela afin de permettre (i) à l’Utilisateur de profiter pleinement des Services et des fonctions proposés par le Site, (ii) prévenir toute fraude et /ou (iii) à des fins statistiques.</p>
          <p>Les données à caractère personnel sont stockées par Le Catalyst en vue de leur traitement dans le cadre de l’utilisation des Services. Elles sont conservées aussi longtemps que nécessaire pour l’apport des Services et fonctions offerts par le Site.</p>
          <p>L’Utilisateur reste toujours propriétaire des informations le concernant qu’il transmet à Le Catalyst. Il dispose, conformément à la loi n° 78-17 du 6 janvier 1978 selon sa version consolidée au 24 mars 2020, d’un droit d’accès, de rectification et de suppression des données à caractère personnel le concernant, ainsi que du droit de s’opposer à la communication de ces données à des tiers pour de justes motifs.</p>
          <p>L’Utilisateur pourra exercer ses droits en écrivant à l’adresse électronique suivante : m.braun.catalyst@gmail.com. Une réponse à la requête de l’Utilisateur lui sera adressée dans un délai de 30 jours.</p>
        </article>

        <article>
          <h2>Article 8 : Responsabilité</h2>
          <p>Il est rappelé que les données publiées par les Utilisateurs et les informations partagées par ces derniers peuvent être captées et exploitées par d’autres Utilisateurs ou des tiers. En ce sens, Le Catalyst ne garantit pas le respect de la propriété de ces données, il incombe à l’Utilisateur de prendre l’ensemble des dispositions nécessaires afin que soit préservée la propriété de ses données.</p>
          <p>Le Catalyst ne garantit pas le fonctionnement sans interruption ou sans erreur de fonctionnement des Services, en particulier, la responsabilité de Le Catalyst ne saurait être engagée en cas d’interruption d’accès à la Plateforme en raison d’opérations de maintenance, de mises à jour ou d’améliorations techniques.</p>
          <p>En tout état de cause, Le Catalyst ne saurait en aucune circonstance être responsable au titre des pertes ou dommages indirects ou imprévisibles de l’Utilisateur ou de tout tiers, ce qui inclut notamment tout gain manqué, tout investissement malheureux, inexactitude ou corruption de fichiers ou données, préjudice d’image ou commercial, perte de chiffre d’affaires ou bénéfice, perte de clientèle ou perte de chance lié à quelque titre et sur quelque fondement que ce soit.</p>
          <p>En outre, Le Catalyst ne saurait être responsable de tout retard ou inexécution du présent Contrat justifié par un cas de force majeure, telle qu’elle est définie par la jurisprudence des cours et tribunaux français.</p>
        </article>
    
        <article>
          <h2>Article 9 : Convention de preuves</h2>
          <p>Les systèmes et fichiers informatiques font foi dans les rapports entre Le Catalyst et l’Utilisateur.</p>
          <p>Ainsi, Le Catalyst pourra valablement produire dans le cadre de toute procédure, aux fins de preuve les données, fichiers, programmes, enregistrements ou autres éléments, reçus, émis ou conservés au moyen des systèmes informatiques exploités, sur tous supports numériques ou analogiques, et s’en prévaloir sauf erreur manifeste.</p>
        </article>
    
        <article>
          <h2>Article 10 : Indivisibilité</h2>
          <p>Le fait que l’une quelconque des dispositions du Contrat soit ou devienne illégale ou inapplicable n’affectera en aucune façon la validité ou l’applicabilité des autres stipulations du Contrat.</p>
        </article>
    
        <article>
          <h2>Article 11 : Règlement des différends</h2>
          <p>La conclusion, l’interprétation et la validité du présent Contrat sont régis par la loi française, quel que soit le pays d’origine de l’Utilisateur ou le pays depuis lequel l’Utilisateur accède à Le Catalyst et nonobstant les principes de conflits de lois.</p>
          <p>Dans l’hypothèse où un différend portant sur la validité, l’exécution ou l’interprétation du présent Contrat et serait porté devant les juridictions civiles, il sera soumis à la compétence exclusive des tribunaux français auquel il est fait expressément attribution de compétence, même en cas de référé ou de pluralité de défendeurs.</p>
          <p>L’Utilisateur est informé qu’il peut en tout état de cause recourir à une médiation conventionnelle ou à tout mode alternatif de règlement des différends (conciliation par exemple) en cas de contestation.</p>
        </article>
    
        <article>
          <h2>Article 12 : Durée des conditions générales d’utilisations</h2>
          <p>Les présentes conditions générales d’utilisation sont conclues pour une durée indéterminée, l’Utilisateur est tenu de les respecter à compter du début de son utilisation des Services.</p>
          <p>L’Editeur se réserve la possibilité de modifier ce document à tout moment et sans préavis. Les utilisateurs seront informés de chaque mise à jour du document.</p>
        </article>
      </div>
    </div>
  );
};

export default MentionsLegales;
